import { DateUtils, OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderOrderingMethodResume = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()
  const method = t(`orderingMethods.${orderSummary.orderingMethod}`).toLowerCase()
  const processedAt = DateUtils.calendar(new Date(orderSummary.processTimestamp)).toLowerCase()

  const _getOrderingMethodResumeSentence = (): string => {
    let label: string = method

    if (orderSummary.orderingMethod === 'table') {
      if (orderSummary.customDeliveryPickupMethod) {
        label += ` ${orderSummary.customDeliveryPickupMethod}`
      }

      if (orderSummary.tableNumber) {
        label += ` ${orderSummary.tableNumber}`
      }
    } else if (orderSummary.orderingMethod === 'delivery') {
      label += ` ${orderSummary.deliveryStreet}, ${orderSummary.deliveryZipcode} ${orderSummary.deliveryLocality}`
    }

    return `${label} ${processedAt}`
  }

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.method')}
      </Typography>

      <Typography variant='h6'>
        {_getOrderingMethodResumeSentence()}
      </Typography>
    </Box>
  )
}

export default OrderOrderingMethodResume
