import { Id, Modifier, ModifierGroup, OrderDetailModifier, OrderDetailModifierGroup, Product } from '@eo-storefronts/eo-core'
import {
  CartProductModifierGroupStateInterface,
  CartProductModifierStateInterface
} from '~/src/stores/cart/cart-product-modifier-groups'

export default class OrderDetailsModifierGroupsToCartModifierGroupMapper {
  private readonly _modifierGroups: ModifierGroup[]
  private readonly _modifiers: Modifier[]
  private readonly _products: Product[]

  public constructor(modifierGroups: ModifierGroup[], modifiers: Modifier[], products: Product[]) {
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
    this._products = products
  }

  public mapModifierGroups(id: Id, modifierGroups: OrderDetailModifierGroup[]): CartProductModifierGroupStateInterface[] {
    return modifierGroups 
      .map((modifierGroup: OrderDetailModifierGroup) => {
        const stateModifierGroup = this._modifierGroups
          .find((mg: ModifierGroup) => mg.uid === modifierGroup.uid)

        if (!stateModifierGroup) {
          throw new Error(`Modifier Group not existing anymore: ${modifierGroup.uid}`)
        }

        const stateModifierGroupId = stateModifierGroup.id.split('-').pop()

        if (!stateModifierGroupId) {
          throw new Error(`Modifier Group: Could not split id ${stateModifierGroup.id}`)
        }

        if (stateModifierGroup.type === 'rank') {
          return {
            id: `r-${id}-${stateModifierGroupId}`,
            modifiers: this._mapProductAsModifier(modifierGroup.modifiers)
          }
        }

        return {
          id: `${id}-${stateModifierGroupId}`,
          modifiers: this._mapModifiers(id, stateModifierGroupId, modifierGroup.modifiers)
        }
      })
  }

  private _mapModifiers(productId: Id, groupId: Id, modifiers: OrderDetailModifier[]): CartProductModifierStateInterface[]
  {
    return modifiers.map((modifier: OrderDetailModifier) => {
      const stateModifier = this._modifiers
        .find((m: Modifier) => m.uid === modifier.uid)

      if (!stateModifier) {
        throw new Error(`Modifier not existing anymore: ${modifier.uid}`)
      }

      const stateModifierId = stateModifier.id.split('-').pop()

      if (!stateModifierId) {
        throw new Error(`Modifier: Could not split id ${stateModifier.id}`)
      }

      return {
        id: `${productId}-${groupId}-${stateModifierId}`,
        quantity: modifier.quantity,
        type: stateModifier.type,
        action: 'add',
        modifierGroups: this.mapModifierGroups(
          groupId,
          modifier.modifierGroups
        )
      }
    })
  }

  private _mapProductAsModifier(modifiers: OrderDetailModifier[]): CartProductModifierStateInterface[]
  {
    return modifiers.map((modifier: OrderDetailModifier) => {
      const stateProduct = this._products
        .find((p: Product) => p.uid === modifier.uid)

      if (!stateProduct) {
        throw new Error(`Product not existing anymore: ${modifier.uid}`)
      }

      return {
        id: stateProduct.id.toString(),
        quantity: modifier.quantity,
        type: 'product',
        action: 'add',
        modifierGroups: this.mapModifierGroups(
          stateProduct.id,
          modifier.modifierGroups
        )
      }
    })
  }
}
