import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Button, Tooltip } from '@mui/material'
import { MouseEvent } from 'react'
import useOrderAgain from '~/src/hooks/orders/useOrderAgain'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps {
  orderSummary: OrderSummary,
}

const OrderAgainButton = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()
  const { orderAgain } = useOrderAgain()

  const _handleOnClick = (e: MouseEvent) => {
    e.stopPropagation()
    orderAgain(orderSummary)
  }

  return (
    <Box {...boxProps}>
      <Tooltip
        title={!orderSummary.reorderPossible ? t('orders.errors.orderAgainImpossible') : ''}
        followCursor={true}
      >
        <span>
          <Button
            variant='contained'
            color='content'
            disabled={!orderSummary.reorderPossible}
            disableElevation
            onClick={_handleOnClick}
          >
            {t('orders.orderAgain')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}

export default OrderAgainButton
