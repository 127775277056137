import { Firm, OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_BY_ID_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderLocation = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()
  const language = useEoValue(LANGUAGE_STATE)
  const firm = useEoValue<Firm|null>(FIRM_BY_ID_SELECTOR(orderSummary.shopId))

  if (!firm) {
    return null
  }

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.orderLocation')}
      </Typography>
      <Typography variant='h6'>
        {firm.name[language]}
      </Typography>
    </Box>
  )
}

export default OrderLocation
