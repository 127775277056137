import { OrderSummary } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import OverridingCartModal from '~/src/components/cart/overriding-cart-modal'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { CART_OF_FIRM_SELECTOR, CART_STATE, CartState } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { CartProduct } from '~/src/types/CartProduct'
import OrderDetailsModifierGroupsToCartModifierGroupMapper from '~/src/utils/mapper/OrderDetailsToCartMapper'

interface ReturnsType {
  orderAgain(order: OrderSummary): void,
}

const useOrderAgain = (): ReturnsType => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { enqueueSnackbar } = useSnackbar()
  const { resolve } = useFirmPathResolver()
  const { present } = useDialog()
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const firm = useEoValue(FIRM_SELECTOR)
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const products = useEoValue(PRODUCTS_STATE)
  const setCart = useSetEoState(CART_STATE)

  const _updateCartAndRedirect = (cartProducts: CartProduct[]) => {
    if (!firm) {
      return enqueueSnackbar({
        variant: 'error',
        message: t('errors.defaultError')
      })
    }

    setCart((state: CartState) => ({
      ...state,
      [firm.id]: cartProducts
    }))

    enqueueSnackbar({
      variant: 'success',
      message: t('cart.updated')
    })

    push(resolve(RoutesEnum.FIRM_HOME))
  }

  const orderAgain = (order: OrderSummary) => {
    const mapper: OrderDetailsModifierGroupsToCartModifierGroupMapper = new OrderDetailsModifierGroupsToCartModifierGroupMapper(
      Object.values(modifierGroups),
      Object.values(modifiers),
      Object.values(products)
    )
    const cartProducts: CartProduct[] = []

    for (const orderDetail of order.orderDetails) {
      try {
        if (!products[orderDetail.id]) {
          throw new Error('Product does not exist anymore')
        }
        
        const cartProduct: CartProduct = {
          id: orderDetail.id,
          quantity: orderDetail.quantity,
          comment: orderDetail.comment,
          modifierGroups: [],
          isCoupon: false
        }

        cartProduct.modifierGroups = mapper.mapModifierGroups(
          orderDetail.id,
          orderDetail.modifierGroups
        )
      
        cartProducts.push(cartProduct)
      } catch (e) {
        enqueueSnackbar({
          variant: 'error',
          message: t('cart.cannot_order_again')
        })

        throw e
      }
    }

    if (cart.length) {
      return present(
        OverridingCartModal,
        'overriding-cart-dialog',
        {
          onClose: (confirm: boolean): void => {
            if (!confirm) {
              return
            }

            _updateCartAndRedirect(cartProducts)
          }
        }
      )
    }

    _updateCartAndRedirect(cartProducts)
  }

  return { orderAgain }
}

export default useOrderAgain
