import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'

const GoShoppingButton = () => {
  const { t } = useTranslations()
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()

  const handleOnClick = () => {
    push(resolve(RoutesEnum.FIRM_HOME))
  }

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={handleOnClick}
    >
      {t('global.goShopping')}
    </Button>
  )
}

export default GoShoppingButton

