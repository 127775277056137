import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import Price from '~/src/components/Price'
import { useTranslations } from '~/src/hooks/useTranslations'
import PriceHelper from '~/src/helpers/priceHelper'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
  includePaymentMethod?: boolean,
}

const OrderTotal = ({ orderSummary, includePaymentMethod = true, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.orderTotal')}
      </Typography>
      <Typography variant='h6'>
        <Price price={PriceHelper.transform(Number(orderSummary.totalPrice))}/>
      </Typography>
      {includePaymentMethod && orderSummary.paymentMethod.name && (
        <Typography variant='body1'>
          {`${t('orders.paymentMethod')}: ${orderSummary.paymentMethod.name}`}
        </Typography>
      )}
    </Box>
  )
}

export default OrderTotal
